import { useLocation, Redirect } from 'react-router-dom';
import { useFetch } from 'bv-hooks';

import {
  Overlay,
  RenderInBody,
  Icon,
  Spinner,
} from 'bv-components';

import { modalBack } from 'bv-helpers/location';
import createQuickDeposit from './api/create_quick_deposit';
import QuickDepositView from './quick_deposit_view';

const QuickDepositModal = () => {
  const location = useLocation();
  const [data, fetching] = useFetch(createQuickDeposit, [location]);
  const { error, ...rest } = data || {};

  if (fetching) {
    return (
      <>
        <Overlay />
        <RenderInBody className="quick-deposit-container">
          <div className="qd-loading-wrapper">
            <Spinner big />
          </div>
        </RenderInBody>
      </>
    );
  }
  if (error) {
    if (error === 'PCC-509') return <Redirect to="/account/verification_zone" />;

    return <Redirect to="/bv_cashier/deposit?first_modal=true" />;
  }

  return (
    <>
      <Overlay fullscreen />
      <RenderInBody className="quick-deposit-container">
        <div className="quick-deposit">
          <span className="quick-deposit__arrow" />
          <span className="close-icon" onClick={modalBack}>
            <Icon type="quick-deposit-close-icon" active className="quick-deposit__close bvs-link" />
          </span>
          <QuickDepositView {...rest} />
        </div>
      </RenderInBody>
    </>
  );
};

export default QuickDepositModal;
